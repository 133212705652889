
import {Component, Ref, Vue} from "vue-property-decorator";
import {
  ExternalMonthlyPlanDto,
  ExternalMonthlySummarizeCreateOrUpdateDto,
  ExternalMonthlySummarizeDetailCreateOrUpdateDto,
  OrganizationUnitDto,
  ExternalProjectSpecificPlanDto,
  ProjectSpecificGoalDto,
  AttachmentHostType,
  ExternalProjectDto,
} from "@/api/appService";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import ProvinceCityArea from "@/components/ProvinceCityArea/index.vue";

@Component({
  name: "ExternalMonthlySummarizeEdit",
  components: {
    ProjectSelect,
    MultipleUploadFile,
    ProvinceCityArea,
  },
})
export default class ExternalMonthlySummarizeEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  monthlyPlayList: ExternalMonthlySummarizeDetailCreateOrUpdateDto[] = [
    {
      id: undefined,

      sequence: undefined,

      /** 具体计划Id */
      projectSpecificPlanId: undefined,

      /** 具体目标Id */
      projectSpecificGoalId: undefined,

      /** 标题 */
      title: undefined,

      /** 开始日期 */
      startDate: undefined,

      /** 结束日期 */
      endDate: undefined,

      /**  */
      completeStatus: undefined,

      /** 内容概述 */
      summary: undefined,

      /**  */
      provinceId: undefined,

      /**  */
      cityId: undefined,

      /**  */
      areaId: undefined,

      /**  */
      townId: undefined,

      /** 地点 */
      place: undefined,

      /** 实到人数 */
      peopleCount: 0,

      /** 原因\/问题 */
      reason: undefined,

      /** 对应策略 */
      tactics: undefined,

      /**  */
      projectId: undefined,

      externalMonthlyPlanDetailId: undefined,

      /**  */
      savedAttachments: [],
    },
  ];
  defaultData: ExternalMonthlySummarizeCreateOrUpdateDto = {
    id: undefined,
    /**  */
    projectId: undefined,

    /** 日期 */
    date: undefined,

    /**  */
    details: [],

    /**  */
    externalMonthlyPlanId: undefined,

    /** 面临的问题 */
    problem: undefined,

    /** 所需资源 */
    needsResource: undefined,

    /**  */
    savedAttachments: [],
  };
  projectType = "";
  projectList: ExternalProjectDto[] = [];
  // 合作方机构列表
  partnerOrgList: OrganizationUnitDto[] = [];
  // 选择后合作方机构id
  partnerOrgIds = [];
  //
  unitList?: OrganizationUnitDto[] = [];
  planList?: ExternalMonthlyPlanDto[] = [];
  form: ExternalMonthlySummarizeCreateOrUpdateDto = {...this.defaultData};
  goalList: ProjectSpecificGoalDto[] = [];
  specificPlanList: ExternalProjectSpecificPlanDto[] = [];
  disabled: boolean = false;

  statusList = [
    {
      value: "None",
      label: "未展开",
    },
    {
      value: "Adjust",
      label: "调整",
    },
    {
      value: "Complete",
      label: "已完成",
    },
  ];

  get detailHostTypeFile() {
    return AttachmentHostType.External_Project_Monthly_Summarize_Photo;
  }

  get hostTypeFile() {
    return AttachmentHostType.External_Project_Monthly_Summarize_Attachment;
  }

  // 选择月计划
  changeMonthlyPlan(e: number) {
    if (e) {
      api.externalMonthlyPlanService
        .get({id: e})
        .then((res: ExternalMonthlyPlanDto) => {
          if (res.details && res.details.length > 0) {
            this.form.details = res.details;
            this.form?.details!.forEach((s) => {
              s.projectSpecificGoalId = s.projectSpecificGoalId ?? "";
              s.projectSpecificPlanId = s.projectSpecificPlanId ?? "";
              s.savedAttachments = s.savedAttachments ?? [];
              s.externalMonthlyPlanDetailId = s.id;
              s.id = 0;
            });
          }
        });
    } else {
      this.form.details = [];
    }
  }

  // 添加一行
  private addRow(r: number) {
    let obj = {
      id: undefined,

      sequence: undefined,

      place: undefined,

      /**  */
      startDate: undefined,

      /**  */
      endDate: undefined,

      /**  */
      summary: undefined,

      /**  */
      partnerOrgId: undefined,

      /**  */
      needSupport: undefined,

      savedAttachments: [],

      externalMonthlyPlanDetailId: undefined,

      /** 摘要 */
      remark: undefined,
    };
    this.form.details = [...this.form.details!, obj];
  }

  // 删除整行
  private deleteRow(index: number) {
    this.form.details!.splice(index, 1);
  }

  created() {
    this.fetchProjectList();
    //
    this.showPlanListByProject("");
    this.fetchOrgAll();
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.disabled = true;
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.externalMonthlySummarize.get({id: this.dataId}).then((res) => {
      this.form = res!;
      this.form.projectId = res.project!.id;
      this.form.savedAttachments = this.form.savedAttachments ?? [];
      this.form?.details!.forEach((s) => {
        s.projectSpecificGoalId = s.projectSpecificGoalId ?? "";
        s.projectSpecificPlanId = s.projectSpecificPlanId ?? "";
        s.savedAttachments = s.savedAttachments ?? [];
      });
      this.getSpecificGoalList();
      this.showPlanListByProject("");
    });
  }

  // 获取机构
  async fetchOrgAll() {
    await api.organizationUnit
      .getAll({maxResultCount: 2000})
      .then((res: any) => {
        this.unitList = res.items;
      });
  }

  //
  // @Watch("form.projectId")
  // async showPlanList() {
  //   if (this.form.projectId ?? 0 > 0) {
  //     this.getSpecificGoalList();
  //     await api.externalMonthlyPlanService
  //       .getMyExternalMonthlyPlanList({
  //         projectId: this.form.projectId,
  //         date: this.form.date + '',
  //       })
  //       .then((res: any) => {
  //         this.planList = res.items!;
  //       });
  //   }
  // }

  async fetchProjectList() {
    await api.externalProject
      // .getExternalProjectListByDate({ date: this.form.date + "-01" })
      .getExternalProjectListByDate({date: ""})
      .then((res) => {
        if (res) {
          this.projectList = res;
        }
      });
  }

  async showPlanList() {
    if (this.form.date) {
      this.fetchProjectList();
    } else {
      this.projectList = [];
      this.form.details = [];
    }
    if (!this.dataId) {
      this.form.projectId = undefined;
      this.form.externalMonthlyPlanId = undefined;
      this.form.details = [];
    }
  }

  async showPlanListByProject(e: any) {
    if (e) {
      this.planList = [];
      this.form!.externalMonthlyPlanId = undefined;
      this.form.details = [];
    }
    if (this.form.date) {
      this.fetchProjectList();
    } else {
      // this.projectList = [];
      // this.form.details = [];
    }

    if ((this.form.projectId ?? 0 > 0)
      //&& this.form.date
    ) {
      this.getSpecificGoalList();
      await api.externalMonthlyPlanService
        .getMyExternalMonthlyPlanList({
          projectId: this.form.projectId,
          //date: this.form.date + "",
        })
        .then((res: any) => {
          this.planList = res;
        });
    }
  }

  getSpecificGoalList() {
    api.externalProject
      .getDetailByProjectId({projectId: this.form.projectId})
      .then((res) => {
        this.goalList = res.effects!.filter(item => item.effect);
        this.goalList.push({id: "", effect: "其他"});
        //
        this.specificPlanList = res.specificPlans!.filter(item => item.title);
        //
      });
  }

  changeActivity(e: string | number, item: any) {
    this.specificPlanList.map(
      (specificPlan: ExternalProjectSpecificPlanDto) => {
        if (specificPlan.id === e) {
          item.title = specificPlan.title;
          return;
        }
      }
    );
  }

  initSpecificPlanList(row: ExternalMonthlySummarizeDetailCreateOrUpdateDto) {
    var result = this.specificPlanList!.filter(function (
      item: ExternalProjectSpecificPlanDto
    ) {
      return item.projectSpecificGoalId == row.projectSpecificGoalId;
    });
    console.log(result);
    return result;
  }

  cancel() {
    this.$router.go(-1);
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.externalMonthlySummarize.update({
            body: {...this.form},
          });
        } else {
          await api.externalMonthlySummarize.create({
            body: {...this.form},
          });
        }
        this.$message.success("更新成功");
        this.$router.go(-1);
      } else {
        this.$message({
          type: "error",
          message: "请检查表单",
        });
      }
    });
  }

  roleRule = {
    date: [
      {
        required: true,
        message: "请选择月份",
        trigger: "blur",
      },
    ],
    projectId: [
      {
        required: true,
        message: "请选择所属项目",
        trigger: "blur",
      },
    ],
    externalMonthlyPlanId: [
      {
        required: true,
        message: "请选择对应月计划",
        trigger: "blur",
      },
    ],
  };
  detailRule = {
    publishTime: [
      {
        required: true,
        message: "请选择发布日期",
        trigger: "change",
      },
    ],
    startDate: [
      {
        required: true,
        message: "请选择开始日期",
        trigger: "change",
      },
    ],
    endDate: [
      {
        required: true,
        message: "请选择结束日期",
        trigger: "change",
      },
    ],
    townId: [
      {
        required: true,
        message: "请选择省市区",
        trigger: "change",
      },
    ],
    place: [
      {
        required: true,
        message: "请输入活动地点",
        trigger: "change",
      },
    ],
    peopleCount: [
      {
        required: true,
        message: "请填写实到人数",
        trigger: "change",
      },
      {
        type: "number",
        message: "实到人数必须为数字",
        trigger: "change",
      },
    ],
    completeStatus: [
      {
        required: true,
        message: "请选择完成状态",
        trigger: "change",
      },
    ],
    projectSpecificGoalId: [
      {
        required: true,
        message: "请选择所属模块",
        trigger: "change",
        validator: validUndefined,
      },
    ],
    title: [
      {
        required: true,
        message: "请填写活动主题",
        trigger: "change",
      },
    ],
    projectSpecificPlanId: [
      {
        required: true,
        message: "请填写活动主题",
        trigger: "change",
      },
    ],
    summary: [
      {
        required: true,
        message: "请输入内容概述",
        trigger: "change",
      },
    ],
  };
}

function validUndefined(rule: any, value: string | undefined, callback: any) {
  if (value == undefined) callback(new Error("请选择所属模块"));
  else callback();
}

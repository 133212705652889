
  import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";
  import api from "@/api";
  import {
    ProvinceDto,
    CityDto,
    AreaDto,
    TownDto,
  } from "@/api/appService";

  export interface ProvinceCityAreaTownDto {
    /**  */
    provinceId?: undefined;

    /**  */
    cityId?: undefined;

    /**  */
    areaId?: undefined;

    townId?: undefined;
  }

  @Component({
    name: "ProvinceCityAreaContent"
  })
  export default class ProvinceCityAreaContent extends Vue {
    @Prop({required: false})
    hostData?: ProvinceCityAreaTownDto;

    provinceList?: ProvinceDto[] = [];
    cityList?: CityDto[] = [];
    areaList?: AreaDto[] = [];
    townList?: TownDto[] = [];

    value: string = ''

    created() {
      this.init()
    }

    async init() {
      if (!this.provinceList || this.provinceList.length === 0) {
        await api.province.getAllList().then((res: any) => {
          this.provinceList = res!;
          this.provinceList!.map(res => {
            if (res.id == this.hostData?.provinceId) {
              this.value += res.provinceName
              return
            }
          })
        });
      }

      if (this.hostData!.provinceId) {
        await this.showCityList();
      }

      if (this.hostData!.cityId) {
        await this.showAreaList();
      }

      if (this.hostData!.areaId) {
        await this.showTownList();
      }
    }


    async showCityList() {
      await api.city
        .getAllList({provinceId: this.hostData!.provinceId})
        .then((res: any) => {
          this.cityList = res!;
          this.cityList!.map(res => {
            if (res.id == this.hostData?.cityId) {
              this.value = this.value + ' ' + res.cityName
              return
            }
          })
        });
    }

    async showAreaList() {
      await api.area.getAllList({cityId: this.hostData!.cityId}).then((res: any) => {
        this.areaList = res!;
        this.areaList!.map(res => {
          if (res.id == this.hostData?.areaId) {
            this.value = this.value + ' ' + res.areaName
            return
          }
        })
      });
    }

    async showTownList() {

      await api.town.getAllList({areaId: this.hostData!.areaId}).then((res: any) => {
        this.townList = res!;
        this.townList!.map(res => {
          if (res.id == this.hostData?.townId) {
            this.value = this.value + ' ' + res.townName
            return
          }
        })
      });
    }

  }


import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";

import {
  ExternalMonthlySummarizeDto,
  AuditFlowScope
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import {UserModule} from "@/store/modules/user";

@Component({
  name: "ExternalMonthlySummarizeList",
  components: {
    // EditLink,
    PagedTableView,
    AgileAuditTag
    // SummarizeDetail
  },
  filters: {
    formatMonth(year: any, month: any) {
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month;
    }
  }
})
export default class ExternalMonthlySummarizeList extends Vue {
  queryForm: any = {
    displayName: "",
    date: ""
  };

  editLinkId: number = 0;
  private currentDetail: ExternalMonthlySummarizeDto = {};

  created() {
    if (this.$route.query.date) {
      this.queryForm.date = this.$route.query.date;
    }
  }

  get canEdit() {
    return !UserModule.roles.find(x => x === "ExternalPartner" || x === "Partner");
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.externalMonthlySummarize.getAll(params);
  }

  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "externalMonthlySummarizeCreate"
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalMonthlySummarizeEdit",
      params: {
        id: row.id
      }
    });
  }

  //  提交审核
  handleStartAudit(index: number, row: ExternalMonthlySummarizeDto) {
    api.externalMonthlySummarize
      .startAudit({body: {id: row.id}})
      .then(() => {
        this.$message.success("已提交");
      });
  }

  handleViewDetail(row: ExternalMonthlySummarizeDto) {
    this.currentDetail = row;
    (this.$refs.summarizeDetail as any).show = true;
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "externalMonthlySummarizeDetail",
      params: {
        id: row.id
      }
    });
  }

  // 删除
  async handleDelete(index: number, row: ExternalMonthlySummarizeDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalMonthlySummarize
        .delete({
          id: row.id
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalMonthlySummarize,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
